// React
import React from "react";

// Styles
import MuiTheme from "../Styles/MuiTheme";

// Context
import Authenticator from "./Authenticator";
import DialogSystem, { DialogContextProvider } from "./DialogSystem";
import SessionContextProvider from "./Session";
import TabContextProvider from "./Tabs";
import DataContextProvider from "./DataLoader";

// Exports
export default function Providers({ children }) {
    return (
        <MuiTheme>
            <SessionContextProvider>
                <Authenticator>
                    <DataContextProvider>
                        <TabContextProvider>
                            <DialogContextProvider>
                                <DialogSystem />
                                {children}
                            </DialogContextProvider>
                        </TabContextProvider>
                    </DataContextProvider>
                </Authenticator>
            </SessionContextProvider>
        </MuiTheme>
    );
}
