// React
import React, { useState } from "react";

// Material UI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Custom Components
import BottomNav from "../components/Navigation/BottomNav";
import Content from "../components/General/Content";

// Styles
import { useStyles } from "../Styles/MuiStyles";

// Exports
export default function Marketing() {
    const { appBarMarketing, mainContainer, full, grey, fullHeight } = useStyles();
    const [tab, setTab] = useState(0);
    return (
        <Box className={[full, grey].join(" ")}>
            <AppBar className={appBarMarketing}>
                <Tabs value={tab} className={fullHeight} classes={{flexContainer: fullHeight}}>
                    <Tab label="Marketing" className={fullHeight}/>
                </Tabs>
            </AppBar>
            <Container maxWidth={false} disableGutters className={mainContainer}>
                <Content />
            </Container>
            <BottomNav />
        </Box>
    );
}
