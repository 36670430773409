// React
import React, { createContext, useContext, useState } from "react";

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

// Styles
import { useStyles } from "../Styles/MuiStyles";

// Exports
const DialogContext = createContext();
export function useDialogContext() {
    const dialogContext = useContext(DialogContext);
    return dialogContext;
}

export function DialogContextProvider(props) {
    const { children } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogProps, setDialogProps] = useState({});

    const dialog = (props) => {
        setDialogProps(props);
        setDialogOpen(true);
    };

    const dialogContext = {
        dialogOpen: dialogOpen,
        setDialogOpen: setDialogOpen,
        dialogProps: dialogProps,
        setDialogProps: setDialogProps,
        dialog: dialog,
    };

    return (
        <DialogContext.Provider value={dialogContext}>
            {children}
        </DialogContext.Provider>
    );
}
export default function DialogSystem() {
    const { dialogOpen, dialogProps, setDialogOpen, setDialogProps } =
        useDialogContext();

    const handleClose = () => {
        setDialogOpen(false);
        setDialogProps({});
    };
    const { dialogPaper } = useStyles();

    if (dialogOpen) {
        const DialogCustomComponent = () => {
            return (
                <dialogProps.component
                    {...dialogProps.componentProps}
                    handleClose={handleClose}
                />
            );
        };

        return (
            <Dialog
                onClose={handleClose}
                open={dialogOpen}
                maxWidth="lg"
                classes={{ paper: dialogPaper }}
            >
                <DialogTitle>{dialogProps.name}</DialogTitle>
                <DialogContent>
                    <DialogCustomComponent />
                </DialogContent>
            </Dialog>
        );
    } else {
        return null;
    }
}
