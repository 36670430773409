// React
import React, { createContext, useContext, useState } from "react";

// Exports
const TabContext = createContext();
export function useTabContext() {
    return useContext(TabContext);
}

export default function TabContextProvider(props) {
    const { children } = props;
    const [tab, setTab] = useState(1);
    const tabContext = { tab: tab, setTab: setTab };
    return (
        <TabContext.Provider value={tabContext}>{children}</TabContext.Provider>
    );
}
