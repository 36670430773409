// React
import React, { useState, useEffect } from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

// Custom Components
import { DialogActionButtons, FormCard } from "./Helpers";
import DatePicker from "../Toolbar/DatePicker";

// Context
import { useDataContext } from "../../Providers/DataLoader";

// Styles
import { useStyles } from "../../Styles/MuiStyles";

// Utilities
import { request } from "../../utilities/httpVerb";

// Exports
function UpdateSettingDialog(props) {
    const { setting, refetchSettings, handleClose } = props;

    const { block, smallMarginBottom, fullWidth } = useStyles();
    const {
        leadSources,
        cacLeadSources,
        cacCategoryLeadSources,
        leadStatuses,
        leadBuckets,
    } = useDataContext();

    const inputClasses = [block, smallMarginBottom, fullWidth].join(" ");
    const [updateSetting, setUpdateSetting] = useState({ ...setting });

    const changeSettingValue = (key, value) => {
        setUpdateSetting({ ...updateSetting, [key]: value });
    };

    const changeAmount = (e) => {
        changeSettingValue("amount", e.target.value);
    };

    const changeActive = (e) => {
        changeSettingValue("active", e.target.checked);
    };

    const changeLeadSource = (e) => {
        changeSettingValue("lead_source", e.target.value);
    };

    const changeCacLeadSource = (e) => {
        changeSettingValue("cac_lead_source", e.target.value);
    };

    const changeCacCategoryLeadSource = (e) => {
        changeSettingValue("cac_category_lead_source", e.target.value);
    };

    const changeReturnableLeadStatuses = (e) => {
        changeSettingValue("returnable_statuses", e.target.value);
    };

    const changeLeadBucket = (e) => {
        changeSettingValue("lead_bucket", e.target.value);
    };

    const changeMethod = (e) => {
        changeSettingValue("method", e.target.value);
    };

    const changeReturnLeadsViaCsv = (e) => {
        changeSettingValue("return_leads_via_csv", e.target.checked);
    };

    const changeLeadReturnEmail = (e) => {
        changeSettingValue("lead_return_email", e.target.value);
    };

    const changePerAppointmentStartDate = (date) => {
        changeSettingValue("per_appointment_start_date", date);
    };

    function patchSetting() {
        request("/settings/" + setting.id, updateSetting, "PATCH").then(() => {
            refetchSettings();
        });
    }

    if (!setting) {
        return null;
    } else {
        return (
            <React.Fragment>
                <FormCard title="Lead Categorization">
                    <FormControl fullWidth disabled>
                        <InputLabel>Lead Source</InputLabel>
                        <Select
                            value={updateSetting.lead_source}
                            onChange={changeLeadSource}
                            className={inputClasses}
                        >
                            {leadSources.map((leadSource) => {
                                return (
                                    <MenuItem
                                        value={leadSource}
                                        key={leadSource}
                                    >
                                        {leadSource}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Cac Lead Source</InputLabel>
                        <Select
                            value={updateSetting.cac_lead_source}
                            onChange={changeCacLeadSource}
                            className={inputClasses}
                        >
                            {cacLeadSources.map((leadSource) => {
                                return (
                                    <MenuItem
                                        value={leadSource}
                                        key={leadSource}
                                    >
                                        {leadSource}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Cac Category Lead Source</InputLabel>
                        <Select
                            value={updateSetting.cac_category_lead_source}
                            onChange={changeCacCategoryLeadSource}
                            className={inputClasses}
                        >
                            {cacCategoryLeadSources.map((leadSource) => {
                                return (
                                    <MenuItem
                                        value={leadSource}
                                        key={leadSource}
                                    >
                                        {leadSource}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Lead Bucket</InputLabel>
                        <Select
                            value={updateSetting.lead_bucket}
                            onChange={changeLeadBucket}
                            className={inputClasses}
                        >
                            {leadBuckets.map((leadBucketOption) => {
                                return (
                                    <MenuItem
                                        value={leadBucketOption}
                                        key={leadBucketOption}
                                    >
                                        {leadBucketOption}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </FormCard>
                <FormCard title="Lead Returns">
                    <FormControl fullWidth>
                        <InputLabel>Returnable Lead Statuses</InputLabel>
                        <Select
                            multiple
                            value={updateSetting.returnable_statuses}
                            onChange={changeReturnableLeadStatuses}
                            renderValue={(selected) => selected.join(", ")}
                            className={inputClasses}
                        >
                            {leadStatuses.map((o) => (
                                <MenuItem key={o} value={o}>
                                    <Checkbox
                                        checked={
                                            updateSetting.returnable_statuses.indexOf(
                                                o
                                            ) > -1
                                        }
                                    />
                                    <ListItemText primary={o} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        defaultValue={setting.lead_return_email}
                        label="Lead Return Email"
                        type="email"
                        className={inputClasses}
                        fullWidth
                        onChange={changeLeadReturnEmail}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={updateSetting.return_leads_via_csv}
                                onChange={changeReturnLeadsViaCsv}
                            />
                        }
                        label="Return Leads Via CSV"
                        className={inputClasses}
                    />
                </FormCard>
                <FormCard title="Pricing">
                    <FormControl fullWidth>
                        <InputLabel>Method</InputLabel>
                        <Select
                            value={updateSetting.method}
                            onChange={changeMethod}
                            className={inputClasses}
                        >
                            <MenuItem value={"Per Lead"}>Per Lead</MenuItem>
                            <MenuItem value={"Per Appointment"}>
                                Per Appointment
                            </MenuItem>
                            <MenuItem value={"Per Day"}>Per Day</MenuItem>
                        </Select>
                    </FormControl>
                    {updateSetting.method === "Per Appointment" && (
                        <DatePicker
                            date={updateSetting.per_appointment_start_date}
                            label="Per Appointment Start Date"
                            onChange={changePerAppointmentStartDate}
                        />
                    )}
                    <TextField
                        defaultValue={updateSetting.amount}
                        label="Amount"
                        type="number"
                        className={inputClasses}
                        fullWidth
                        onChange={changeAmount}
                    />
                </FormCard>
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked={updateSetting.active}
                            onChange={changeActive}
                        />
                    }
                    label="Active"
                />
                <DialogActionButtons
                    onClick={patchSetting}
                    handleClose={handleClose}
                    name="Update"
                />
            </React.Fragment>
        );
    }
}

export default function openUpdateDialog(props) {
    const { dialog, refetchSettings, id, settings } = props;
    const setting = settings.find((setting) => setting.id === id);
    const dialogProps = {
        name: `Update ${setting.lead_source} Setting`,
        component: UpdateSettingDialog,
        componentProps: {
            setting: setting,
            refetchSettings: refetchSettings,
        },
    };
    dialog(dialogProps);
}
