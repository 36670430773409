// React
import React, { useEffect, useState } from "react";

// Material UI
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { DataGrid } from "@material-ui/data-grid";

// Custom Components
import openNewSettingDialog from "../components/Dialogs/NewSetting";
import openUpdateSettingDialog from "../components/Dialogs/UpdateSetting";

// Context
import { useDialogContext } from "../Providers/DialogSystem";
import { useDataContext } from "../Providers/DataLoader";

// Styles
import { useStyles } from "../Styles/MuiStyles";

// Exports
export default function Settings() {
    const { table, spaceBetween } = useStyles();
    const { dialog } = useDialogContext();
    const { settings, refetchSettings } = useDataContext();
    const [activeFilter, setActiveFilter] = useState("Active");

    const getColumns = (settings) => {
        return [
            { field: "lead_source", headerName: "Lead Source", width: 200 },
            {
                field: "cac_lead_source",
                headerName: "CAC Lead Source",
                width: 200,
            },
            {
                field: "cac_category_lead_source",
                headerName: "CAC Category Lead Source",
                width: 200,
            },
            { field: "amount", headerName: "Amount", width: 150 },
            { field: "method", headerName: "Method", width: 150 },
            {
                field: "active",
                headerName: "Active",
                width: 150,
                renderCell: (params) => (
                    <Checkbox disabled checked={params.value} />
                ),
            },
            {
                field: "id",
                headerName: "Edit",
                width: 150,
                renderCell: (params) => (
                    <IconButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                            openUpdateSettingDialog({
                                settings: settings,
                                id: params.value,
                                dialog: dialog,
                                refetchSettings: refetchSettings,
                            })
                        }
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        ];
    };

    const columns = getColumns(settings);
    var filteredSettings = settings;

    if (activeFilter == "Active") {
        filteredSettings = settings.filter((setting) => setting.active);
    } else if (activeFilter == "Inactive") {
        filteredSettings = settings.filter((setting) => !setting.active);
    }

    return (
        <React.Fragment>
            <div className={spaceBetween}>
                <Active active={activeFilter} setActive={setActiveFilter} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        openNewSettingDialog({
                            dialog: dialog,
                            refetchSettings: refetchSettings,
                        })
                    }
                >
                    New Setting
                </Button>
            </div>
            <Paper elevation={3} className={table}>
                <DataGrid
                    rows={filteredSettings}
                    columns={columns}
                    pageSize={10}
                />
            </Paper>
        </React.Fragment>
    );
}

const Active = (props) => {
    const { active, setActive } = props;
    const { thirdWidth } = useStyles();

    const changeActive = (e) => setActive(e.target.value);
    return (
        <FormControl className={[thirdWidth].join(" ")}>
            <InputLabel>Active</InputLabel>
            <Select value={active} onChange={changeActive}>
                {["All", "Active", "Inactive"].map((status) => (
                    <MenuItem value={status} key={status}>
                        {status}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
