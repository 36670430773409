// Exports
export async function post(url = "", data = {}) {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      'X-CSRF-Token': token
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function request(url = "", data = {}, type = "GET") {
  const csrfToken = document.querySelector("[name='csrf-token']").content;
  const properties = {
    method: type,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  }
  properties.body = JSON.stringify(data)
  const response = await fetch(url, properties);
  return response.json(); // parses JSON response into native JavaScript objects
}
