// React
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

// Components
import Providers from "../Providers/Providers";
import Marketing from "../Pages/Marketing";

const App = () => {
    return (
        <Providers>
            <Router key="Router">
                <Route exact path="/">
                    <Marketing />
                </Route>
                <Route exact path="/marketing">
                    <Marketing />
                </Route>
            </Router>
        </Providers>
    );
};

const div = document.createElement("div");
div.setAttribute("style", "height:100%;");

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(<App />, document.body.appendChild(div));
});
