// React
import React from "react";

// Material UI
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useStyles } from "../../Styles/MuiStyles";

// Exports
export default function DatePicker(props) {
    const { date, label, onChange } = props;
    const themeObject = {
        direction: "ltr",
        overrides: {},
    };
    const theme = createTheme(themeObject);
    const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
                <KeyboardDatePicker
                    style={{ marginBottom: "16px", width: "100%" }}
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    id={label}
                    label={label}
                    value={date}
                    onChange={onChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                        classes: { root: classes.inputAdornment },
                    }}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}
