// React
import React, { useState } from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Custom Components
import { DialogActionButtons, FormCard } from "./Helpers";

// Styles
import { useStyles } from "../../Styles/MuiStyles";

// Utilities
import { request } from "../../utilities/httpVerb";

// Exports
function NewUserDialog(props) {
    const { refetchUsers, handleClose } = props;
    const { block, smallMarginBottom, noMargin, black } = useStyles();
    const inputClasses = [block, smallMarginBottom, black].join(" ");

    const defaultUser = {
        name: "",
        email: "",
        admin: false,
        suspended: false,
    };

    const [newUser, setNewUser] = useState(defaultUser);
    const changeUserValue = (key, value) => {
        setNewUser({ ...newUser, [key]: value });
    };

    const changeName = (e) => {
        changeUserValue("name", e.target.value);
    };

    const changeEmail = (e) => {
        changeUserValue("email", e.target.value);
    };

    const changeAdmin = (e) => {
        changeUserValue("admin", e.target.checked);
    };

    const changeSuspended = (e) => {
        changeUserValue("suspended", e.target.checked);
    };

    function createUser() {
        if (!!newUser) {
            request("/registrations/create", newUser, "POST").then(() => {
                refetchUsers();
            });
        }
    }

    return (
        <React.Fragment>
            <FormCard>
                <TextField
                    color="primary"
                    label="Name"
                    className={inputClasses}
                    fullWidth
                    defaultValue={newUser.name}
                    onChange={changeName}
                />
                <TextField
                    defaultValue={newUser.email}
                    label="Email"
                    className={inputClasses}
                    fullWidth
                    onChange={changeEmail}
                />
                <FormControlLabel
                    control={<Checkbox defaultChecked={defaultUser.checked} />}
                    className={noMargin}
                    label="Admin"
                    labelPlacement="start"
                    onChange={changeAdmin}
                />
                <FormControlLabel
                    control={
                        <Checkbox defaultChecked={defaultUser.suspended} />
                    }
                    label="Suspended"
                    labelPlacement="start"
                    onChange={changeSuspended}
                />
            </FormCard>
            <DialogActionButtons
                onClick={createUser}
                handleClose={handleClose}
                name="Create"
            />
        </React.Fragment>
    );
}

export default function openNewUserDialog(props) {
    const { dialog, refetchUsers } = props;

    const dialogProps = {
        name: "Create User",
        component: NewUserDialog,
        componentProps: { refetchUsers: refetchUsers },
    };
    dialog(dialogProps);
}
