// React
import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Exports
const drawerWidth = 300;
const appBarHeight = "64px";
const toolsHeight = "70px";

export const useStyles = makeStyles((theme) => ({
    appBarMarketing: { height: appBarHeight, position: "static !important" },
    tools: { height: `${toolsHeight} !important` },
    mainContainer: {
        height: `calc(100% - ${appBarHeight} - ${toolsHeight})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    table: {
        height: "80%",
        width: "100%",
        marginTop: theme.spacing(2),
    },
    dialogPaper: {
        backgroundColor: "#f4f4f4",
        minWidth: "650px",
    },
    fw: { width: "100%" },
    fullWidth: { width: "100%" },
    halfWidth: { width: "50%" },
    thirdWidth: { width: "33%" },
    quarterWidth: { width: "23%" },
    full: { width: "100%", height: "100%" },
    thirtyHeight: { height: "30%" },
    fullHeight: { height: "100%" },
    grey: { backgroundColor: "#f2f2f2" },
    ml1: { marginLeft: theme.spacing(2) },
    noMargin: {
        marginTop: "0px !important",
        marginBottom: "0px !important",
        marginLeft: "0px !important",
        marginRight: "0px !important",
    },
    smallMarginVertical: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    smallMarginBottom: {
        marginBottom: theme.spacing(2),
    },
    smallMarginRight: {
        marginRight: theme.spacing(2),
    },
    largeMarginVertical: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
    },
    largeMarginTop: {
        marginTop: theme.spacing(10),
    },
    verticalColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    block: { display: "block" },
    spaceBetween: {
        height: "7.5%",
        minHeight: "32px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    rightAligner: {
        height: "7.5%",
        minHeight: "32px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    body: {
        width: "100%",
        height: "100%",
        display: "flex",
    },
    bodyColumn: {
        width: "100%",
        height: "100%",
    },
    black: { color: "black" },
    toolbarEnd: { display: "flex", width: "50%", justifyContent: "flex-end" },
    appBarSpacer: theme.mixins.toolbar,
    accordionDetails: { flexDirection: "column" },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    hiddenDrawer: { width: "0%", flexShrink: 0 },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: { justifyContent: "space-between" },
    lineChartContainer: { height: "100%", width: "70%" },
    inputAdornment: { color: "white" },
    appBarInput: { color: "white" },
    whiteUnderline: { borderBottom: "1px solid white" },
    white: { color: "white" },
    search: { width: "50%" },
    dashboardSelect: { width: "20%", color: "white" },
    toolbarLeft: {
        width: "50%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    banner: {
        width: "100%",
        height: "20%",
        minHeight: "80px",
    },
    login: {
        minHeight: "200px",
    },
    errorMessage: { color: "red" },
    block: { display: "block" },
    cardContent: {
        padding: theme.spacing(3),
        height: `calc(70% - ${theme.spacing(3) * 2}px)`,
    },
    cardActions: {
        padding: theme.spacing(3),
        height: `calc(30% - ${theme.spacing(3) * 2}px)`,
    },
}));
