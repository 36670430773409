// React
import React from "react";

// Pages
import Admin from "../../Tabs/Admin";
import Settings from "../../Tabs/Settings";

// Context
import { useTabContext } from "../../Providers/Tabs";

// Exports
export default function Content() {
    const { tab } = useTabContext();
    switch (tab) {
        case 1:
            return <Settings />;
        case 2:
            return <Admin />;
        default:
            return null;
    }
}
