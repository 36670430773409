// React
import React, { useState, useEffect } from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Custom Components
import { DialogActionButtons, FormCard } from "./Helpers";

// Styles
import { useStyles } from "../../Styles/MuiStyles";

// Utilities
import { request } from "../../utilities/httpVerb";

// Exports
function UpdateUserDialog(props) {
    const { user, handleClose, refetchUsers } = props;
    const { block, smallMarginBottom, noMargin } = useStyles();
    const inputClasses = [block, smallMarginBottom].join(" ");

    const [updateUser, setUpdateUser] = useState({ ...user });
    const changeUserValue = (key, value) => {
        setUpdateUser({ ...updateUser, [key]: value });
    };

    const changeName = (e) => {
        changeUserValue("name", e.target.value);
    };

    const changeEmail = (e) => {
        changeUserValue("email", e.target.value);
    };

    const changeAdmin = (e) => {
        changeUserValue("admin", e.target.checked);
    };

    const changeSuspended = (e) => {
        changeUserValue("suspended", e.target.checked);
    };

    function patchUser() {
        request("/registrations/update", updateUser, "POST").then(() => {
            refetchUsers();
        });
    }

    return (
        <React.Fragment>
            <FormCard>
                <TextField
                    label="Name"
                    defaultValue={user.name}
                    onChange={changeName}
                    className={inputClasses}
                    fullWidth
                />
                <TextField
                    label="Email"
                    defaultValue={user.email}
                    onChange={changeEmail}
                    className={inputClasses}
                    fullWidth
                />
                <FormControlLabel
                    label="Admin"
                    onChange={changeAdmin}
                    control={<Checkbox defaultChecked={user.admin} />}
                    className={noMargin}
                    labelPlacement="start"
                />
                <FormControlLabel
                    label="Suspended"
                    control={<Checkbox defaultChecked={user.suspended} />}
                    onChange={changeSuspended}
                    className={noMargin}
                    labelPlacement="start"
                />
            </FormCard>
            <DialogActionButtons
                onClick={patchUser}
                handleClose={handleClose}
                name="Update"
            />
        </React.Fragment>
    );
}

export default function openUpdateDialog(props) {
    const { users, id, dialog } = props;
    const user = users.find((user) => user.id === id);
    const dialogProps = {
        name: "Update User",
        component: UpdateUserDialog,
        componentProps: {
            user: user,
            refetchUsers: props.refetchUsers,
        },
    };
    dialog(dialogProps);
}
