// React
import React from "react";

// Components
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

// Exports
export default function MuiTheme({ children }) {
    const themeObject = {
        direction: "ltr",
        overrides: {
            MuiDataGrid: { root: { height: "100%" } },
        },
    };
    const theme = createTheme(themeObject);
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
