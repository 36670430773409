// React
import React from "react";

// Material UI
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export function FormCard(props) {
    return (
        <Card style={{ padding: "16px", marginBottom: "16px" }}>
            {!!props.title && (
                <CardHeader
                    title={props.title}
                    style={{ padding: "0px" }}
                    titleTypographyProps={{ variant: "subtitle1" }}
                />
            )}
            {props.children}
        </Card>
    );
}

export function DialogActionButtons(props) {
    const buttonStyle = {
        height: "100%",
        marginLeft: "16px",
    };
    return (
        <DialogActions>
            <Button
                onClick={() => {
                    props.onClick();
                    props.handleClose();
                }}
                color="primary"
                variant="contained"
                style={buttonStyle}
            >
                {props.name}
            </Button>
            <Button
                onClick={props.handleClose}
                color="primary"
                variant="outlined"
                style={buttonStyle}
            >
                Close
            </Button>
        </DialogActions>
    );
}
