// React
import React from "react";

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

// Styles
import { useStyles } from "../Styles/MuiStyles";

// Exports
export default function Loader() {
  const { zIndex2 } = useStyles();
  return (
    <Backdrop open className={zIndex2}>
      <CircularProgress />
    </Backdrop>
  );
}
