// React
import React, { createContext, useContext, useState, useEffect } from "react";

// Custom Components
import Loader from "./Loader";

// Context
import { useSessionContext } from "./Session";

// Exports
const DataContext = createContext();
export function useDataContext() {
    return useContext(DataContext);
}

export default function DataContextProvider(props) {
    const { children } = props;
    const { session } = useSessionContext();
    const { authenticated, user } = session;

    const [users, setUsers] = useState([]);
    const [settings, setSettings] = useState([]);
    const [leadSources, setLeadSources] = useState([]);
    const [cacLeadSources, setCacLeadSources] = useState([]);
    const [cacCategoryLeadSources, setCacCategoryLeadSources] = useState([]);
    const [leadStatuses, setLeadStatuses] = useState([]);
    const [leadBuckets, setLeadBuckets] = useState([]);
    const [regions, setRegions] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [userCounter, setUserCounter] = useState(0);
    const [settingsCounter, setSettingsCounter] = useState(0);

    const refetchUsers = () => {
        setUserCounter(userCounter + 1);
    };

    const refetchSettings = () => {
        setSettingsCounter(settingsCounter + 1);
    };

    useEffect(() => {
        if (authenticated && user.admin) {
            fetch("/sessions/index")
                .then((res) => res.json())
                .then((users) => {
                    setUsers(users);
                })
                .then(() =>
                    fetch("/settings")
                        .then((res) => res.json())
                        .then((settings) => {
                            setSettings(settings);
                        })
                )
                .then(() =>
                    fetch("/lead_sources")
                        .then((res) => res.json())
                        .then((leadSources) => {
                            setLeadSources(leadSources);
                        })
                )
                .then(() =>
                    fetch("/cac_lead_sources")
                        .then((res) => res.json())
                        .then((cacLeadSources) => {
                            setCacLeadSources(cacLeadSources);
                        })
                )
                .then(() =>
                    fetch("/cac_category_lead_sources")
                        .then((res) => res.json())
                        .then((cacCategoryLeadSources) => {
                            setCacCategoryLeadSources(cacCategoryLeadSources);
                        })
                )
                .then(() =>
                    fetch("/lead_statuses")
                        .then((res) => res.json())
                        .then((leadStatuses) => {
                            setLeadStatuses(leadStatuses);
                        })
                )
                .then(() =>
                    fetch("/regions")
                        .then((res) => res.json())
                        .then((regions) => {
                            setRegions(regions);
                        })
                )
                .then(() =>
                    fetch("/lead_buckets")
                        .then((res) => res.json())
                        .then((leadBuckets) => {
                            setLeadBuckets(leadBuckets);
                            setLoaded(true);
                        })
                );
        } else {
            setLoaded(true);
        }
    }, [userCounter, settingsCounter]);

    const dataContext = {
        users: users,
        setUsers: setUsers,
        refetchUsers: refetchUsers,
        settings: settings,
        setSettings: setSettings,
        refetchSettings: refetchSettings,
        leadSources: leadSources,
        cacLeadSources: cacLeadSources,
        cacCategoryLeadSources: cacCategoryLeadSources,
        leadStatuses: leadStatuses,
        leadBuckets: leadBuckets,
        regions: regions,
        loaded: loaded,
    };

    if (loaded) {
        return (
            <DataContext.Provider value={dataContext}>
                {children}
            </DataContext.Provider>
        );
    } else {
        return <Loader />;
    }
}
