// React
import React from "react";

// Material UI
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import AdminIcon from "@material-ui/icons/Settings";
import DollarIcon from "@material-ui/icons/AttachMoney";
import WalletIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";

// Context
import { useSessionContext } from "../../Providers/Session";
import { useTabContext } from "../../Providers/Tabs";

// Styles
import { useStyles } from "../../Styles/MuiStyles";

// Utilities
import { request } from "../../utilities/httpVerb";

// Exports
export default function BottomNav() {
    const { session, setSession } = useSessionContext();
    const { tab, setTab } = useTabContext();
    const { tools } = useStyles();

    const handleLogout = () => {
        const logout = {
            email: session.user.email,
        };
        request("/users/sign_out", logout, "DELETE").then(() => {
            setSession({ authenticated: false });
            setJobs([]);
            setUsers([]);
            setTab(0);
        });
    };

    return (
        <BottomNavigation
            value={tab}
            onChange={(e, newValue) => {
                setTab(newValue);
            }}
            showLabels
            className={tools}
        >
            <BottomNavigationAction
                label="Logout"
                icon={<LogoutIcon />}
                onClick={handleLogout}
            />
            <BottomNavigationAction label="Settings" icon={<DollarIcon />} />
            {session.user.admin ? (
                <BottomNavigationAction label="Admin" icon={<AdminIcon />} />
            ) : null}
        </BottomNavigation>
    );
}
