// React
import React, { useState, useEffect } from "react";

// Material UI
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";

// Custom Components
import openUpdateDialog from "../components/Dialogs/UpdateUser";
import openNewUserDialog from "../components/Dialogs/NewUser";

// Styles
import { useStyles } from "../Styles/MuiStyles";

// Context
import { useDataContext } from "../Providers/DataLoader";
import { useDialogContext } from "../Providers/DialogSystem";
import { request } from "../utilities/httpVerb";

// Exports
export default function AdminPage() {
    const { users, refetchUsers } = useDataContext();
    const { dialog } = useDialogContext();
    const { table, rightAligner } = useStyles();

    const getColumns = (props) => {
        const { users, dialog } = props;
        return [
            { field: "name", headerName: "Name", width: 200 },
            { field: "email", headerName: "Email", width: 150 },
            {
                field: "admin",
                headerName: "Admin",
                width: 100,
                renderCell: (params) => (
                    <Checkbox checked={params.value} disabled />
                ),
            },
            {
                field: "suspended",
                headerName: "Suspended",
                width: 150,
                renderCell: (params) => (
                    <Checkbox checked={params.value} disabled />
                ),
            },
            {
                field: "id",
                headerName: "Edit",
                renderCell: (params) => (
                    <IconButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                            openUpdateDialog({
                                users: users,
                                id: params.value,
                                dialog: dialog,
                                refetchUsers: refetchUsers,
                            })
                        }
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        ];
    };

    const columns = getColumns({
        users: users,
        dialog: dialog,
    });

    return (
        <React.Fragment>
            <div className={rightAligner}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        openNewUserDialog({
                            dialog: dialog,
                            refetchUsers: refetchUsers,
                        })
                    }
                >
                    New User
                </Button>
            </div>
            <Paper elevation={5} className={table}>
                <DataGrid rows={users} columns={columns} pageSize={10} />
            </Paper>
        </React.Fragment>
    );
}
