// React
import React, { useState } from "react";

// Material UI
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Styles
import { useStyles } from "../Styles/MuiStyles";

// Utilities
import { request } from "../utilities/httpVerb"
import { useSessionContext } from "../Providers/Session";

// Exports
export default function LoginPage() {
  const { setSession } = useSessionContext();
  const {
    banner,
    errorMessage,
    login,
    block,
    cardContent,
    cardActions,
    thirtyHeight,
    fullHeight,
    fullWidth,
    verticalColumn,
    smallMarginVertical,
    largeMarginTop,
  } = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleLogin = (e) => {
    const login = {
      email: email,
      password: password,
    };
    request("/users/sign_in", login, "POST").then((res) => {
      if (!res.authenticated) {
        setError(true);
      } else if (res.authenticated) {
        setError(false);
        setSession(res);
      }
    });
  };

  return (
    <Container maxWidth="sm" className={[fullHeight, verticalColumn].join(" ")}>
      <div
        className={[
          "FreedomSolarLogoLargeBanner",
          banner,
          smallMarginVertical,
        ].join(" ")}
      />
      <Card
        className={[fullWidth, thirtyHeight, largeMarginTop, login].join(" ")}
        elevation={5}
      >
        <CardContent className={cardContent}>
          <TextField
            label="Email"
            fullWidth
            className={[block].join(" ")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError(false);
            }}
          />
          <TextField
            label="Password"
            fullWidth
            className={[block].join(" ")}
            type={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError(false);
            }}
          />
          {error ? (
            <Typography
              variant="body2"
              className={[errorMessage, smallMarginVertical].join(" ")}
            >
              Invalid Credentials
            </Typography>
          ) : null}
        </CardContent>
        <CardActions className={cardActions}>
          <Button
            color="primary"
            variant="contained"
            className={fullWidth}
            onClick={handleLogin}
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
}